import React from "react";
import "./Warranty.scss";

const Warranty = () => {
  return (
    <div className="warranty">
      <div className="seal">
        <img
          loading="lazy"
          src="/images/warranty-orange.png"
          alt="Garantia de 7 dias"
        />
      </div>
      <h2>
        <span className="highlight">Risco ZERO</span>, Garantia Incondicional
      </h2>
      <p>
        Eu tenho certeza que investir em conhecimento é a melhor forma de
        investir. Pois conhecimento é um ativo que ninguém nunca irá te tirar, e
        o saber, tem um valor imensurável.
      </p>
      <p>
        Porém a gente nunca conta com os imprevistos. Pensando nisso eu vou
        deixar você tranquilo, protegendo seu investimento por até&nbsp;
        <strong>7 dias</strong>.
      </p>
      <p>
        Você compra o curso e caso aconteça algum imprevisto e você queira
        cancelar a compra, basta enviar um email para&nbsp;
        <strong>suporte@emersonbroga.com</strong> e será solicitada a devolução
        do seu dinheiro no nosso gateway de pagamentos.
      </p>
      <p>
        Sem perguntas, sem ressentimentos... de forma simples mesmo. E tá tudo
        bem, continuamos amigos.
      </p>
    </div>
  );
};

export default Warranty;
