import React from "react";
import Youtube from "emersonbroga-youtube-embed";
import SEO from "src/components/SEO";
import FAQ2 from "src/sections/FAQ2";
import Warranty from "src/sections/Warranty";
import Testimonials from "src/sections/Testimonials";
import About from "src/sections/About";
import CourseContent from "src/sections/Course-Content";
import Purchase from "src/components/CTA/Purchase";
import Footer from "src/sections/Footer";

import { BASE_URL, NAME } from "src/helpers/config";
import { getJSPrices, formatPrice } from "src/helpers/hotmart";
import { getUrlParam } from "src/helpers/gatsby";
import faqJS from "src/data/faq-js.json";
import courseContentJS from "src/data/course-content-js.json";

import "./style.scss";

const JSCourse = () => {
  const promo = getUrlParam("promo");

  const jsPrices = getJSPrices(promo);
  const {
    full,
    downpayment,
    installment,
    helperText,
    checkout,
    videoId,
  } = jsPrices;

  const seoProps = {
    title: `Curso de JavaScript - Inscrições abertas - ${NAME}`,
    canonical: `${BASE_URL}/curso-javascript/`,
    description: `Aprenda a dominar a linguagem JavaScript e escrever código com tranquilidade e entregar seus projetos com confiança.`,
  };

  const ctaProps = {
    title: "Quero me tornar aluno(a)",
    secondaryTitle: "Meus dados",
    submitLabel: "Prosseguir",
    checkout: checkout,
    isModal: false,
  };

  return (
    <div className="js-course ">
      <SEO {...seoProps} />

      <section className="presentation dark">
        <div className="intro boxed">
          {/* <h1 className="sunset text-clip">
            Curso de JavaScript
            <small>com Emerson Brôga</small>
          </h1 className="sunset text-clip"> */}
          <div className="header">
            <div className="image">
              <img
                src="/images/538x380-curso-logo.png"
                alt="Logo Curso JavaScript com Emerson Brôga"
              />
            </div>
            <h2>
              Escreva <span className="sunset text-clip">JavaScript</span> com
              tranquilidade e entregue seus projetos com confiança!
            </h2>
          </div>

          <div className="promo">
            <div className="video">
              <Youtube videoId={videoId} />
            </div>

            <div className="text">
              <div className="promo-text">
                <h1 className="">
                  Curso de JavaScript
                  <small>com Emerson Brôga</small>
                </h1>
                <p>
                  Domine de uma vez por todas a linguagem mais poderosa da Web.
                </p>
                <p>
                  Escreva JavaScript com tranquilidade e entregue seus projetos
                  com confiança!
                </p>
              </div>
              {/* <p>
                Quer trabalhar para qualquer lugar do mundo e ganhar os ótimos
                salários que a profissão de programador pode proporcionar?
              </p>
              <p>
                Chegou a hora de você se comprometer e se dedicar a sua
                carreira.
              </p> */}
              <div className="promo-pricing">
                <h3 className="text-clip sunset">
                  Curso direto ao ponto, de forma clara e objetiva, sem
                  enrolação.
                </h3>
                <p className="full-price">
                  de <strike>R$ {formatPrice(full)}</strike>
                </p>
                <p className="downpayment">
                  Por <strong>{formatPrice(downpayment)}</strong> à vista
                </p>
                <p className="coupom">{helperText}</p>
                <p className="installments">
                  <span>12x</span> de&nbsp;
                  <strong>{formatPrice(installment)}</strong>
                  <small>*</small>
                </p>
              </div>
            </div>
          </div>
        </div>
        <Purchase {...ctaProps} />
        <div className="image illustration">
          <img
            load="lazy"
            draggable="false"
            src="/images/course-js-node-react.png"
            alt="Curso de React e Node JS do Emerson Brôga"
          />
        </div>
      </section>

      {/* <section className="course-details">
        <div className="boxed">
          <h3 className="sunset text-clip">O que tem no Curso?</h3>
          <div className="video">
            <Youtube videoId="rmPwW95K1rk" />
          </div>
        </div>
      </section> */}

      <Testimonials />

      <FAQ2 data={faqJS.data} />

      <Warranty />

      <CourseContent data={courseContentJS.data} />

      <About />

      <Footer />
      <div className="more-details">
        <p>Links</p>
        <a
          href="https://www.glassdoor.com.br/Sal%C3%A1rios/desenvolvedor-front-end-sal%C3%A1rio-SRCH_KO0,23.htm"
          target="_blank"
          rel="noopener noreferrer"
        >
          Pesquisa do GlassDoor
        </a>
        <a
          href="https://www.foxsports.com/presspass/latest-news/2020/02/03/super-bowl-liv-fox-draws-viewership-102-million-across-television-digital-platforms"
          target="_blank"
          rel="noopener noreferrer"
        >
          Matéria sobre o SuperBowl
        </a>
        <small>* Tarifa de 2,49% a.m.</small>
      </div>
    </div>
  );
};
export default JSCourse;
