import React, { useState } from "react";
import Icons from "src/components/Icons";

const CourseContent = ({ data }) => {
  const [openIndex, setOpenIndex] = useState(null);

  return (
    <section className="course-content ">
      <div className="boxed">
        <div className="illustration">
          <h3 className="sunset text-clip">Conteúdo do Curso</h3>
          <Icons id="illustration-course-content" />
        </div>
        <div className="qa">
          {data.map(({ q, a }, idx) => {
            return (
              <div
                key={idx}
                className={`item ${openIndex === idx ? "open" : ""}`}
              >
                <div
                  className="q"
                  onClick={e =>
                    setOpenIndex(prevIndex => (prevIndex === idx ? null : idx))
                  }
                  onKeyDown={e => {}}
                  role="button"
                  aria-hidden="true"
                >
                  <p>{q}</p>
                  <Icons
                    id={
                      openIndex === idx
                        ? "chevron-circle-up"
                        : "chevron-circle-down"
                    }
                  />
                </div>
                {openIndex === idx ? (
                  <div className="a">
                    {a.split("\n").map((text, idx) => (
                      <p>
                        {idx + 1} - {text}
                      </p>
                    ))}
                  </div>
                ) : null}
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};
export default CourseContent;
